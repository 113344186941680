import { cache, createAsync, useParams } from "@solidjs/router";
import { ErrorBoundary, Show } from "solid-js";
import { getGiftBoxRedirectionRouteData } from "~/server/data/gift_box_redirection_route_data";

export type GiftBoxType = "MULTI_BRAND" | "SINGLE_BRAND";

export default function Gift() {
  const GiftRedirectionRouteData$C = cache(
    getGiftBoxRedirectionRouteData,
    "GiftRedirectionRouteData"
  );

  const params = useParams();
  const giftingKey = params.giftingKey as string;

  const routeData = createAsync(
    async () => {
      return await GiftRedirectionRouteData$C(giftingKey);
    },
    { deferStream: true }
  );

  return (
    <ErrorBoundary
      fallback={(error) => (
        <div class="bg-baseDark flex h-full w-full flex-col items-center justify-center text-h3 text-baseTertiaryLight">
          {error.message}
        </div>
      )}
    >
      <Show when={routeData()}>
        <div></div>
      </Show>
    </ErrorBoundary>
  );
}
